<template>
  <div>
    <CRow class="justify-content-between mb-3">
      <CCol sm="auto"><div class="ui-type-display-lg">Carrelli</div></CCol>
    </CRow>

    <div class="loading text-center" v-if="loading">
      <CSpinner color="primary" size="lg" />
    </div>
    <div v-else-if="markets.length > 0">
      <MarketCart
        v-for="market in markets"
        :key="market.id"
        :market="market"
        :loadProducts="loadProducts(market)"
      />

      <CPagination
        v-if="numPages > 1"
        :active-page.sync="currentPage"
        :pages="numPages"
        sime="sm"
        responsive
      />
    </div>
    <div v-else>Tutti i carrelli sono vuoti</div>
  </div>
</template>

<script>
import get from "lodash/get";
import { GetDataManagerNew, ApiCall, Query, Predicate } from "../../ds";
import { MarketCart } from "./partials";
import { mapGetters, mapState } from "vuex";

export default {
  name: "UiMarketCarts",
  components: {
    MarketCart,
  },

  data() {
    return {
      initialized: false,
      loading: false,
      markets: [],
      numPages: 1,
      currentPage: 1,
    };
  },

  computed: {
    ...mapState("shop", ["market"]),

    ...mapGetters("connections", ["familyId"]),
  },

  mounted() {
    this.initialized = true;
    this.getMarkets();
  },

  watch: {
    currentPage: function () {
      this.getMarkets();
    },
  },

  methods: {
    getMarkets() {
      const self = this;
      self.loading = true;

      const predicate = new Predicate("cart", "notequal", null);
      const query = new Query().where(predicate);
      const itemsPerPage = 100;
      query.page(1, itemsPerPage);

      ApiCall(
        GetDataManagerNew("family_markets", [this.familyId]),
        query,
        (e) => {
          self.numPages = Math.ceil(e.actual.payload.Count / itemsPerPage);
          self.markets = e.result;
          self.loading = false;
        },
        (e) => {
          console.error(e);
          self.loading = false;
        }
      );
    },

    loadProducts(market) {
      return (
        this.markets.length === 1 ||
        market.market.id === get(this.market, "market.id", null)
      );
    },
  },
};
</script>
